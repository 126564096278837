// Variables
@import "variables";


:root {
    --sidebar-bg: rgb(51, 53, 51);
    --sidebar-text: rgb(207, 219, 213);
    --sidebar-strong: rgb(207, 219, 213);
    --sidebar-footer: rgb(207, 219, 213);
    --main-text: rgb(207,219,213);
    --main-bg: rgb(36, 36, 35);
    --accent-color: rgb(255,105,180);
    --gradient: 36, 36, 35;
    --main-head-text: rgb(207,219,213);
    --project-card: rgb(51, 53, 51);
}

//* Setting up the Content Sections
.main-page {
    display: flex;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100vh;
    margin-left: $sidebar-margin;
    scroll-behavior: smooth;
    background-color: var(--main-bg);
  
    .content-section {
      flex: 0 0 auto;
      width: calc((100vw - 265px) / 1);
      overflow-y: auto;
      max-height: 100vh;
      min-height: 100vh;
      color: var(--main-text);
    }

    .content-section a {
        color: var(--main-text);
      }

    .content-section {
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    .content-section::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }

    .inner-container {
        margin: 50px;
        font-family: $body-font;
    }

}

// Containers for Education
#education-container {
    padding-top: 20px;
}

#cv-link {
    margin: 50px;
    display:inline-block;
}

#cv-link ul {
    list-style-position: outside;
}

.educard {
    min-width: 450px;
    width: 30%;
    margin-right: 40px;
    margin-bottom: 40px;
    padding:0;
    float:left; 
    color: $educard-font;
}

.educard .info {
    border-top: 1px solid $educard-border-color;
    border-left: 1px solid $educard-border-color;
    border-right: 1px solid $educard-border-color;
    border-top-left-radius: $educard-border-radius;
    border-top-right-radius: $educard-border-radius;
    -webkit-box-shadow: 0px 10px 10px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 10px 10px rgba(0,0,0,0.2);
    box-shadow: 0px 10px 10px rgba(0,0,0,0.2);
    height: 180px;
    line-height: 20px;
    background-color: $educard-bg-color;
}

.educard .school {
    border: 0px solid $educard-border-color;
    background-color: $school-bg-color;
    color: rgb(235, 235, 235);
    border-bottom-left-radius: $educard-border-radius;
    border-bottom-right-radius: $educard-border-radius;
    -webkit-box-shadow: 0px 10px 10px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 10px 10px rgba(0,0,0,0.2);
    box-shadow: 0px 10px 10px rgba(0,0,0,0.2);
    height: 70px;
}

.educard .logo {
    float: left;
    margin: 10px;
    text-align: center;
}

.educard .school-name {
    font-family: Oswald;
    font-size: 35px;
    text-align: right;
    margin: auto 20px;
}

.educard .year {
    font-size: 18px;
    font-weight: 600;
    color: $educard-font;
}

.educard h4 {
    font-family: Oswald;
    text-transform: uppercase;
    font-size: 21px;
    font-weight: 400;
}

.educard h3 {
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 17px;
    font-weight: 600;
}

.educard h5 {
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 14px;
    font-weight: 400;
}


// Experience Page CSS
.jobcards {
    display: grid;
    grid-template-columns: [icon] 1fr [year] 4fr [experience] 14fr;
    border-top: 1px solid darkgray;
    color: var(--main-text);
    font-family: $job-exp-font;
}

.jobcards .year {
    grid-area: "year";
    text-align: left;
    padding: 0px 10px;
    margin: 40px 0px;
    line-height: 25px;
}

.jobcards .year h4 {
    font-size: 25px;
    font-weight: 100;
    line-height: 30px;
    margin: 0px;
}

.jobcards .year h5 {
    font-size: 18px;
    font-weight: 200;
    margin: 0px;
}

.jobcards .year h6 {
    font-weight: 500;
    font-size: 14px;
    margin: 0px;
    -webkit-font-smoothing: antialiased;
}

.jobcards .experience {
    grid-area: "experience";
    font-size: 16px;
    text-align: left;
    padding: 0px 10px;
    margin: auto 0px;
    font-family: $job-exp-font;
}

.jobicon {
    float:right;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-right: 0px;
    margin-left: 0px;
}

.jobicon i {
    font-size: 60px;
}

.jobcards i {
    transition: font-size .25s;
}

.jobcards:hover {
    i {
        font-size: 65px;
        color: var(--accent-color);
    }
}

// Skills
.skill-container #area {
    font-family: $header-font;
    font-weight: 300;
    font-size: 2em;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 50px;
    color: var(--main-text);
    width: 400px;
    float:left;
    text-align: right;
}

.skill-card-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    width: 50%;
}

.skill-card {
    padding: 5px 20px;
    margin: 10px;
    border: 2px solid var(--main-text);
    border-radius: 10px;
    text-align: center;
    font-size:22px;
    color: var(--main-text);
}


// About Section
#abt-sup {
    background-image: url('/images/pelican.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;
    
    .gradient {
        width:100%;
        height: 100%;
        background-image: linear-gradient(to left, rgba(var(--gradient), .6), rgba(var(--gradient), .95), rgba(var(--gradient), 1));
        z-index: 1;
    }

    h1 {
        margin: 0;
    }

    h2 {
        font-variant: small-caps;
    }

    #short-desc {
        text-align: justify;
        width: 50%;
    }
}

#about {
    .inner-container {
        min-width: 50%;
    }

    h1 {
        font-family: Noto Sans TC;
        font-size:80px;
    }
}

// Project Cards
#projects-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    // justify-content: space-evenly;
}

.project-card {
    margin: 20px;
    width: 80%;
    min-width: 300px;
    border-left: 1px solid var(--accent-color);
    background-color: var(--project-card);

    .project-title {
        font-size: 24px;
        margin-left:10px;
        margin-top: 10px;
        font-weight: 600;
        text-transform: uppercase;
        i {
            font-size: 18px;
        }
    }

    .project-skill-container {
        display: flex;
        flex-wrap: wrap;
    }

    .project-skills {
        font-size: 14px;
        margin: 5px 0 5px 15px;
        p {
            background-color: var(--accent-color);
            border: 2px solid var(--accent-color);
            border-radius: 25px;
            color: var(--main-bg);
            margin: auto;
            padding: 2px 5px;
        }
    }

    .project-desc {
        margin: 18px;
        min-height: 100px;
        text-align:justify;
        font-size: 14px;
    }

    .project-link {
        height:40px;
        text-align: right;
        margin-top: 10px;
        margin-right: 10px;

        a {
            padding:5px;
            // border: 2px solid var(--accent-color);
        }
    }

    .project-footer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
}

// General Text Formatting
.main-page .title {
    // text-transform: uppercase;
    font-weight: 400;
    font-family: $header-font;
    color: var(--main-head-text);
    font-size: 45px;
}

.title-divider {
    width: 20%;
    height: 2px;
    background-color: var(--accent-color);
    margin: 10px 0 30px 0;
}

// Fix for smaller windows
/* On screens that are less than 700px wide, make the nav-panel into a topbar */
@media screen and (max-width: 700px) {
    .main-page {
      flex-direction: column;
      .content-section {
        flex: 0 0 auto;
        width: 100vw;
        overflow-x: auto;
        max-height: 100vh;
      }
    }

    #abt-sup {
        #short-desc {
            // make more room for text when screen is small 
            min-width: 90%;
        }
    }

    .skill-container #area {
        // text align left when screen is small
        text-align:left;
    }

    .jobcards {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }
    .jobcards .experience {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        padding-bottom: 10px;
    }

    .main-page .title {
        padding-top: 15px;
    }

    .main-page .title-divider {
        width: 50%;
    }
  }
  