// Imports
@font-face {
  font-family: 'icomoon';
  src: url("/fonts/icomoon/icomoon.eot?srf3rx");
  src: url("/fonts/icomoon/icomoon.eot?srf3rx#iefix") format("embedded-opentype"), url("/fonts/icomoon/icomoon.ttf?srf3rx") format("truetype"), url("/fonts/icomoon/icomoon.woff?srf3rx") format("woff"), url("/fonts/icomoon/icomoon.svg?srf3rx#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; };

@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Muli&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather-Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

// -------- Fonts ----------
// General Header / Body
$header-font: 'Quicksand', sans-serif;
$body-font: 'Open Sans', sans-serif;

// Experience Fonts
$job-exp-font: $body-font;

// Sidebar Fonts
$sidebar-font: $header-font;

// ------- Misc --------
// Education Card Data
$educard-border-radius: 15px;
$educard-border-color: rgb(172, 172, 172);
$educard-bg-color: whitesmoke;
$school-bg-color:  #1e0233;
$educard-font: rgb(51,53,51);

// Sizing
$sidebar-width: 250px;
$sidebar-padding: 15px;
$sidebar-margin: $sidebar-width + $sidebar-padding;



// // Desert shades
// $color-main-bg: #472d30;
// $color-accent: #e26d5c;
// $color-content-bg: rgb(201,203,163);
// $color-main-font: rgb(201,203,163);
// $color-secondary-font: rgb(201,203,163);
// $color-container-bg: rgb(114,61,70);


// // Forest Green Gradient (Dark)
// $color-main-bg: rgb(47, 62, 70);
// $color-accent: rgb(132, 169, 140);
// $color-content-bg: rgb(132,169,140);
// $color-main-font: rgb(202,210,197);
// $color-secondary-font: rgb(202,210,197);
// $color-container-bg: rgb(53,79,82);

// // Forest Green Gradient (Light)
// $color-main-bg: #52796f;
// $color-accent: #2f3e46;
// $color-content-bg: rgb(132,169,140);
// $color-main-font: rgb(47,62,70);
// $color-secondary-font: rgb(202,210,197);
// $color-container-bg: rgb(202,210,197);

// blue and orange
// $color-main-bg: #293241;
// $color-accent: #ee6c4d;
// $color-content-bg: rgb(224,251,252);
// $color-main-font: rgb(224,251,252);
// $color-secondary-font: rgb(224,251,252);
// $color-container-bg: rgb(61,90,128);

$SimplerLimeGreen: rgb(125, 206, 148);

$ScuffedDarkGrey: rgb(61, 61, 63);

$VanillaGrey: rgb(246, 245, 243);

$White-ish: rgb(238, 231, 220) ;