//@import './variables.scss';
// When importing the master, you leave out the underscore, and it
// will look for a file with the underscore. This prevents the SCSS
// compiler from generating a CSS file from it.
@import "variables";


/* The side navigation menu */
.nav-panel {
    margin: 0; /* Can be used to create 'floating' tile look*/
    padding: 40px 0px;
    width: $sidebar-width;
    padding-left: $sidebar-padding;
    background-color: var(--sidebar-bg); 
    position: fixed;
    height: 100%;
    overflow: auto;
    text-align: center;
  }
  
ul#nav a {
  color: var(--sidebar-text);
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: .25em;
  display: inline-block;
  padding: 10px;
  position: relative;
  font-family: $sidebar-font;//'Open Sans';
}
ul#nav a:hover:after {
  width: 100%;
  left: 0;
}
ul#nav a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  width: 0;
  left: 0;
  position: absolute;
  background: var(--accent-color);
  transition: width .6s ease;
  -webkit-transition: width .6s ease;
}

/* Remove list bullets */
ul#nav li {
  list-style: none;
}

/* Nav Panel UL  */
ul#nav {
  text-align: center;
  padding: 0;
}

/* Author Image */
.author-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  margin-bottom: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%; 
}

/* Icons Panel */
ul#icons {
  text-align:center;
  margin: 0;
  padding: 0;
}

ul#icons li {
  display:inline;
}

ul#icons a { 
  display:inline-block;
  color: var(--sidebar-text);
  text-align: center;
  padding: 10px;
  text-decoration: none;
}

ul#icons i {
  font-size: 20px;
  font-weight: 200;
}

img#aligned {
  vertical-align: middle;
} 

.nav-panel  h2 {
  margin: 1em 0 0.5em 0;
  color: var(--sidebar-strong);
  font-weight: normal;
  font-size: 25px;
  line-height: 40px;
  font-family: $sidebar-font;
}

.nav-panel h4 {
  margin: 1em 0 0.5em 0;
	color: var(--sidebar-strong);
	font-size: 20px;
	line-height: 10px;
	font-weight: normal;
	font-family: $sidebar-font;
}

#sidefoot {
  color: var(--sidebar-footer);
}

#sidefoot i, a {
  color: var(--sidebar-footer);
}

// Made the dropdown invisible by default
.sidebarMenu, .sidebarMenuInner, .sidebarIconToggle, .openSidebarMenu {
  display: none;
}

.mobileHeader {
  display:none;
}

// Toggle for Light/Dark mode
button {
  border-radius: 100px;
  padding: 4px;
  border: 0px;
  color: var(--sidebar-text);
  background-color: var(--sidebar-bg);
  // background: linear-gradient(180deg, 
  //   var(--sidebar-bg) 33%, var(--accent-color) 33%, 
  //   var(--accent-color) 66%,  var(--sidebar-bg) 66%);
  box-sizing: border-box;
  font-family: $sidebar-font;
  margin: 10px;
}

button:focus {
  outline: none; 
}

button.Light {
  background-color: var(--accent-color);
  color: #DDD;

  i {
    // float: left;
    font-size: 18px;
    margin-right: 20px;
  }
}

button.Dark {
  background-color: var(--accent-color);
  color: #333;

  i {
    // float: right;
    font-size: 18px;
    margin-left: 20px;
  }
}

// Switch Toggle
p#Light {
  color: #333
}
p#Dark {
  color: #EEE;
}

/* On screens that are less than 700px wide, make the nav-panel into a topbar */
/* display the bar vertically, instead of horizontally */
@media screen and (max-width: 700px) {
  .nav-panel, .menu {
    display: none;
  }

  div.main-page {margin-left: 0;}

  button {
    margin: 20px;
  }

  p#Light,
  p#Dark {
    margin-left: 20px;
  }

  // menu
  .sidebarMenu {
    height: 100%;
    position: fixed;
    left: 0;
    width: 250px;
    margin-top: 60px;
    transform: translateX(-250px);
    transition: transform 250ms ease-in-out;
    background-color: var(--sidebar-bg);
    }

  .sidebarMenuInner{
      margin:0;
      padding:0;
  }

  .sidebarMenuInner li{
      list-style: none;
      color: var(--sidebar-text);
      padding: 20px;
      cursor: pointer;
  }

  .sidebarMenuInner li span{
      display: block;
      font-size: 14px;
      color: var(--sidebar-text);
  }
  .sidebarMenuInner li a{
      color: var(--sidebar-text);
      text-transform: uppercase;
      letter-spacing: .25em;
      cursor: pointer;
      text-decoration: none;
  }
  input[type="checkbox"]:checked ~ .sidebarMenu {
      transform: translateX(0);
      box-shadow: 100px 0px 1000px rgba(0, 0, 0, 0.5);
  }

  input[type=checkbox] {
      transition: all 0.3s;
      box-sizing: border-box;
      display: none;
  }
  .sidebarIconToggle {
      transition: all 0.3s;
      box-sizing: border-box;
      cursor: pointer;
      position: absolute;
      z-index: 99;
      height: 100%;
      width: 100%;
      top: 22px;
      left: 15px;
      height: 22px;
      width: 22px;
  }
  .spinner {
      transition: all 0.3s;
      box-sizing: border-box;
      position: absolute;
      height: 3px;
      width: 100%;
      background-color: var(--accent-color);
  }
  .horizontal {
      transition: all 0.3s;
      box-sizing: border-box;
      position: relative;
      float: left;
      margin-top: 3px;
  }
  .diagonal.part-1 {
      position: relative;
      transition: all 0.3s;
      box-sizing: border-box;
      float: left;
  }
  .diagonal.part-2 {
      transition: all 0.3s;
      box-sizing: border-box;
      position: relative;
      float: left;
      margin-top: 3px;
  }
  input[type=checkbox]:checked ~ .sidebarIconToggle > .horizontal {
      transition: all 0.3s;
      box-sizing: border-box;
      opacity: 0;
  }
  input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
      transition: all 0.3s;
      box-sizing: border-box;
      transform: rotate(135deg);
      margin-top: 8px;
  }
  input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
      transition: all 0.3s;
      box-sizing: border-box;
      transform: rotate(-135deg);
      margin-top: -9px;
  }

  .sidebarMenu, .sidebarMenuInner, .sidebarIconToggle, .openSidebarMenu {
    display: block;
  }

  .mobileHeader {
    display: block;
    margin: 10 auto;
    width: 100%;
    max-width: 100%;
    background-color: var(--sidebar-bg);
    position: fixed;
    height: 60px!important;
    overflow: hidden;
    z-index: 10;
    text-align: right;
  }

  .mobileHeader #MyName {
    margin: 1em 0 0.5em 0;
    color: var(--sidebar-strong);
    font-weight: normal;
    font-size: 18px;
    font-family: $sidebar-font;
    margin: 22px;
  }

}